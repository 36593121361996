import { FormattedHTMLMessage } from "gatsby-plugin-intl"
import React from "react"
import "./Intro.css"

const Intro = () => (
  <div className="Intro ">
    <FormattedHTMLMessage id="intro-message:label" />
  </div>
)

export default Intro
